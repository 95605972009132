export const PER_PAGE = 'perPage';
export const PAGE = 'page';
export const SORT_BY = 'sortBy';
export const STATUS = 'status';
export const COMPANY_ID = 'companyId';
export const DURATION = 'duration';
export const SUBDOMAIN_ID = 'subdomainId';
export const NAME = 'name';
export const COUNTRY_ID = 'countryId';
export const PRODUCT_NAME = 'productName';
export const ITEM_NUMBER = 'itemNumber';
export const AGE = 'age';
export const TAB = 'tab';
export const ITEM = 'item';
export const ENFORCEMENT_TYPE = 'enforcementType';
export const ENFORCEMENT_ACTION_TYPE = 'enforcementActionType';
export const ENFORCEMENT_STATUS = 'enforcementStatus';
export const STORE_NAME = 'storeName';
export const STORE_ID = 'sellerId';
export const COMPLETE_TEST_PURCHASE_ID = 'completeTestPurchaseId';
export const COMPLETE_ENFORCEMENT_ID = 'completeEnforcementId';
export const VOLUNTARILY_DELIST_ENFORCEMENT_ID = 'voluntarilyDelistEnforcementId';
export const IS_ACTIVE = 'isActive';
export const IS_VERIFORM_SUBMITTED = 'isVeriformSubmitted';
export const PHRASE = 'phrase';
export const HAS_ASSOCIATED_BOTS = 'hasAssociatedBots';
export const EMAIL_PRESENCE = 'emailPresence';
export const SCHEDULING = 'scheduling';
export const ALLOW_REMOTE_ADMIN = 'allowRemoteAdmin';
export const ENFORCEABLE_AREA_ID = 'enforceableAreaId';
export const DASHBOARDID = 'dashboardid';
export const STORE_STATUS = 'storeStatus';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const ROLE_ID = 'roleId';
export const BRAND_ID = 'brandId';
export const DASHBOARD_ACTIVITY_DISABLED_LINES = 'dashboardActivityDisabledLines';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  BreadcrumbMatch,
  BreadcrumbComponentType,
  BreadcrumbsRoute,
} from 'use-react-router-breadcrumbs';

import {
  getAdminBotsRoute,
  getAdminKeywordsRoute,
  getAdminTrademarksRoute,
  getBotsRoute,
  getChannelRoute,
  getChannelsRoute,
  getCompaniesRoute,
  getCompanyBotsRoute,
  getCompanyNotificationTemplatesRoute,
  getCompanyTrademarksRoute,
  getDataProvidersRoute,
  getEnforcementsRoute, getKeywordsRoute, getListingsRoute,
  getResellersRoute,
  getStoresRoute,
  getTestPurchasesRoute,
  getTrademarkOfficesRoute,
} from '@/utils/routes';
import type {
  BreadcrumbCustomProps,
  DisableOnParam,
} from '@/types/shared';

type UseBreadcrumbsComponentsConfig = {
  CompanyBreadcrumb: BreadcrumbComponentType;
  UserBreadcrumb: BreadcrumbComponentType;
  TrademarkBreadcrumb: BreadcrumbComponentType;
  BotBreadcrumb: BreadcrumbComponentType;
  BotRunBreadcrumb: BreadcrumbComponentType;
  ResellerBreadcrumb: BreadcrumbComponentType;
  StoreBreadcrumb: BreadcrumbComponentType;
  ListingBreadcrumb: BreadcrumbComponentType;
  TestPurchaseBreadcrumb: BreadcrumbComponentType;
  EnforcementBreadcrumb: BreadcrumbComponentType;
  SettingsChannelBreadcrumb: BreadcrumbComponentType;
  DataProviderBreadcrumb: BreadcrumbComponentType;
  SettingsChannelSubdomainBreadcrumb: BreadcrumbComponentType;
  NotificationTemplateBreadcrumb: BreadcrumbComponentType;
  KeywordBreadcrumb: BreadcrumbComponentType;
  ReportBreadcrumb: BreadcrumbComponentType;
  TrademarkOfficeBreadcrumb: BreadcrumbComponentType;
  LoaBreadcrumb: BreadcrumbComponentType;
  BrandBreadcrumb: BreadcrumbComponentType;
};

type UseBreadcrumbsConfig = {
  components: UseBreadcrumbsComponentsConfig;
};

type BreadcrumbRouteConfig = Omit<BreadcrumbsRoute, 'props'> & {
  props?: BreadcrumbCustomProps;
};

export const useBreadcrumbsConfig = ({
  components,
}: UseBreadcrumbsConfig) => {
  const { t } = useTranslation();

  const breadcrumbsConfig = useMemo<BreadcrumbRouteConfig[]>(() => ([
    {
      path: '/companies',
      breadcrumb: t('navigation:navItem.companies'),
      props: {
        iconName: 'Company',
        persistedQueryStringKey: getCompaniesRoute(),
      },
    },
    {
      path: '/companies/:companyId',
      breadcrumb: components.CompanyBreadcrumb,
    },
    {
      path: '/companies/:companyId/users',
      breadcrumb: t('navigation:breadcrumbs.companyUsers'),
    },
    {
      path: '/companies/:companyId/users/:userId',
      breadcrumb: components.UserBreadcrumb,
    },
    {
      path: '/admin',
      breadcrumb: t('navigation:navItem.administration'),
      props: {
        iconName: 'Administration',
      },
    },
    {
      path: '/admin/users',
      breadcrumb: t('navigation:breadcrumbs.companyUsers'),
    },
    {
      path: '/admin/users/:userId',
      breadcrumb: components.UserBreadcrumb,
    },
    {
      path: '/admin/trademarks',
      breadcrumb: t('navigation:breadcrumbs.trademarks'),
      props: {
        persistedQueryStringKey: getAdminTrademarksRoute(),
      },
    },
    {
      path: '/admin/trademarks/:trademarkId',
      breadcrumb: components.TrademarkBreadcrumb,
    },
    {
      path: '/admin/harvesters',
      breadcrumb: t('navigation:breadcrumbs.bots'),
      props: {
        persistedQueryStringKey: getAdminBotsRoute(),
      },
    },
    {
      path: '/admin/harvesters/:botId',
      breadcrumb: components.BotBreadcrumb,
    },
    {
      path: '/admin/harvesters/keywords',
      breadcrumb: t('keyword:keywords'),
      props: {
        persistedQueryStringKey: getAdminKeywordsRoute(),
      },
    },
    {
      path: '/admin/harvesters/keywords/:keywordId',
      breadcrumb: components.KeywordBreadcrumb,
    },
    {
      path: '/admin/letters-of-authorisations',
      breadcrumb: t('loa:loas'),
    },
    {
      path: '/admin/letters-of-authorisations/:loaId',
      breadcrumb: components.LoaBreadcrumb,
    },
    {
      path: '/admin/brands',
      breadcrumb: t('brand:brands'),
    },
    {
      path: '/admin/brands/:brandId',
      breadcrumb: components.BrandBreadcrumb,
    },
    {
      path: '/companies/:companyId/trademarks',
      breadcrumb: t('trademark:trademarks'),
      props: {
        persistedQueryStringKey: (match) => getCompanyTrademarksRoute(match.params.companyId),
      },
    },
    {
      path: '/companies/:companyId/trademarks/:trademarkId',
      breadcrumb: components.TrademarkBreadcrumb,
    },
    {
      path: '/companies/:companyId/harvesters/:botId/runs/:botRunId',
      breadcrumb: components.BotRunBreadcrumb,
    },
    {
      path: '/companies/:companyId/harvesters',
      breadcrumb: t('bot:bots'),
      props: {
        persistedQueryStringKey: (match) => getCompanyBotsRoute(match.params.companyId),
      },
    },
    {
      path: '/companies/:companyId/harvesters/keywords',
      breadcrumb: t('keyword:keywords'),
      props: {
        persistedQueryStringKey: (match) => getKeywordsRoute(match.params.companyId),
      },
    },
    {
      path: '/companies/:companyId/harvesters/keywords/:keywordId',
      breadcrumb: components.KeywordBreadcrumb,
    },
    {
      path: '/companies/:companyId/harvesters/:botId',
      breadcrumb: components.BotBreadcrumb,
    },
    {
      path: '/companies/:companyId/letters-of-authorisations',
      breadcrumb: t('loa:loas'),
    },
    {
      path: '/companies/:companyId/letters-of-authorisations/:loaId',
      breadcrumb: components.LoaBreadcrumb,
    },
    {
      path: '/companies/:companyId/brands',
      breadcrumb: t('brand:brands'),
    },
    {
      path: '/companies/:companyId/brands/:brandId',
      breadcrumb: components.BrandBreadcrumb,
    },
    {
      path: '/companies/:companyId/notification-templates',
      breadcrumb: t('navigation:breadcrumbs.notificationTemplates'),
      props: {
        // eslint-disable-next-line max-len
        persistedQueryStringKey: (match) => getCompanyNotificationTemplatesRoute(match.params.companyId),
      },
    },
    {
      path: '/companies/:companyId/notification-templates/:notificationTemplateId',
      breadcrumb: components.NotificationTemplateBreadcrumb,
    },
    {
      path: '/harvesters',
      breadcrumb: t('navigation:breadcrumbs.bots'),
      props: {
        iconName: 'Bot',
        persistedQueryStringKey: getBotsRoute(),
      },
    },
    {
      path: '/harvesters',
      breadcrumb: t('navigation:breadcrumbs.bots'),
    },
    {
      path: '/harvesters/:botId',
      breadcrumb: components.BotBreadcrumb,
    },
    {
      path: '/harvesters/:botId/runs/:botRunId',
      breadcrumb: components.BotRunBreadcrumb,
    },
    {
      path: '/sellers',
      breadcrumb: t('navigation:breadcrumbs.sellers'),
      props: {
        iconName: 'Resellers',
        persistedQueryStringKey: getStoresRoute(),
      },
    },
    {
      path: '/sellers/:storeId',
      breadcrumb: components.StoreBreadcrumb,
    },
    {
      path: '/sellers/parent-entities',
      breadcrumb: t('navigation:breadcrumbs.parentEntities'),
      props: {
        customHref: getResellersRoute(),
        persistedQueryStringKey: getResellersRoute(),
      },
    },
    {
      path: '/sellers/parent-entities/:resellerId',
      breadcrumb: components.ResellerBreadcrumb,
    },
    {
      path: '/listings',
      breadcrumb: t('navigation:breadcrumbs.listings'),
      props: {
        iconName: 'Searches',
        persistedQueryStringKey: getListingsRoute(),
      },
    },
    {
      path: '/listings/:listingId',
      breadcrumb: components.ListingBreadcrumb,
    },
    {
      path: '/actions',
      breadcrumb: t('navigation:breadcrumbs.enforcements'),
      props: {
        iconName: 'FileGavel',
        persistedQueryStringKey: getEnforcementsRoute(),
      },
    },
    {
      path: '/actions/:enforcementId',
      breadcrumb: components.EnforcementBreadcrumb,
    },
    {
      path: '/actions/test-purchases',
      breadcrumb: t('navigation:breadcrumbs.testPurchases'),
      props: {
        customHref: getTestPurchasesRoute(),
        persistedQueryStringKey: getTestPurchasesRoute(),
      },
    },
    {
      path: '/actions/test-purchases/:testPurchaseId',
      breadcrumb: components.TestPurchaseBreadcrumb,
    },
    {
      path: '/settings',
      breadcrumb: t('navigation:breadcrumbs.settings'),
      props: {
        iconName: 'Administration',
      },
    },
    {
      path: '/settings/harvesting/channels',
      breadcrumb: t('navigation:breadcrumbs.channels'),
      props: {
        persistedQueryStringKey: getChannelsRoute(),
      },
    },
    {
      path: '/settings/harvesting/channels/:channelId',
      breadcrumb: components.SettingsChannelBreadcrumb,
      props: {
        persistedQueryStringKey: (match) => getChannelRoute(match.params.channelId),
      },
    },
    {
      path: '/settings/harvesting/channels/:channelId/subdomains/:subdomainId',
      breadcrumb: components.SettingsChannelSubdomainBreadcrumb,
    },
    {
      path: '/settings/harvesting/data-providers',
      breadcrumb: t('navigation:breadcrumbs.dataProviders'),
      props: {
        persistedQueryStringKey: getDataProvidersRoute(),
      },
    },
    {
      path: '/settings/harvesting/data-providers/:dataProviderId',
      breadcrumb: components.DataProviderBreadcrumb,
    },
    {
      path: '/analytics',
      breadcrumb: t('navigation:breadcrumbs.reports'),
      props: {
        iconName: 'ReportChart',
      },
    },
    {
      path: '/analytics/:reportId',
      breadcrumb: components.ReportBreadcrumb,
    },
    {
      path: '/settings/analytics',
      breadcrumb: t('navigation:breadcrumbs.reports'),
    },
    {
      path: '/settings/analytics/:reportId',
      breadcrumb: components.ReportBreadcrumb,
    },
    {
      path: '/settings/trademarks/trademark-offices',
      breadcrumb: t('navigation:breadcrumbs.trademarkOffices'),
      props: {
        persistedQueryStringKey: getTrademarkOfficesRoute(),
      },
    },
    {
      path: '/settings/trademarks/trademark-offices/:trademarkOfficeId',
      breadcrumb: components.TrademarkOfficeBreadcrumb,
    },
  ]), [
    t,
    components,
  ]);

  return breadcrumbsConfig;
};

export const checkIsDisabled = (
  match: BreadcrumbMatch,
  disableOnParam: DisableOnParam,
) => {
  return match.params[disableOnParam.name] === disableOnParam.value;
};
